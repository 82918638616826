import axios, { type AxiosError } from 'axios';
import type  { httpError, httpResponse, PageQuery, GetByID } from './http_types';
// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    const url = config.url;
    // 判断 url 是否包含 http 或者 https
    if (url && url.indexOf('http') !== 0) {
      config.url = `${import.meta.env.VITE_APP_BASE_API || ''}${url}`;
    }
    // 根据 config.url 找到 所有的 query 参数, 检查 key 的值 是否包含了 undefined，null 如果包含了就删除
    var query = config.url?.split('?')[1];
    if (query) {
      var queryArr = query.split('&');
      var newQueryArr = queryArr.filter((item) => {
        var key = item.split('=')[0];
        var value = item.split('=')[1];
        if (value === 'undefined' || value === 'null') {
          return false;
        }
        return true;
      });
      config.url = `${config.url?.split('?')[0]}?${newQueryArr.join('&')}`;
    }

    const website = useWebsiteStore();

    const token = website.token;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  }
);

// 拦截响应
axios.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return response as httpResponse;
  },
  (error) => {
    const errorStore =useErrorStore()
    // 对响应错误做点什么
    const axiosErr = error as AxiosError;
    if(axiosErr?.response){
      const res = axiosErr.response as unknown as httpResponse;
      if(res){
        if (res.data.message == "未登录") {
          const router = useRouter();
          router.push('/login')
        }
      }
      errorStore.setResult(res.data as httpError);
      return Promise.reject(res as httpResponse);
    }else{
      errorStore.setResult(axiosErr as httpError);
    }
    return Promise.reject(axiosErr as unknown as httpResponse);
  }
);

// 配合 axios 拦截 重新定义axios的返回值类型 以便于后续的类型推断，这里的 data 实际上是后端返回的数据
async function post(path: string, body?: any) {
  try {
    const res = await axios.post(path, body);
    const data = res as unknown as httpResponse;
    return new Promise<httpResponse>((resolve) => {
      resolve(data);
    });
  } catch (error) {
    return Promise.reject(error as unknown as httpResponse);
  }
}

async function get(path: string, query?: PageQuery) {
  try {
    if (query) {
      path = `${path}?${new URLSearchParams(query as any)}`;
    }
    const res = await axios.get(path);
    const data = res as unknown as httpResponse;
    return new Promise<httpResponse>((resolve) => {
      resolve(data);
    });
  } catch (error) {
    return Promise.reject(error as unknown as httpResponse);
  }
}

async function put(path: string, body?: any) {
  try {
    const res = await axios.put(path, body);
    const data = res as unknown as httpResponse;
    return new Promise<httpResponse>((resolve) => {
      resolve(data);
    });
  } catch (error) {
    return Promise.reject( error as unknown as httpResponse);
  }
}

async function del(path: string) {
  try {
    const res = await axios.delete(path);
    const data = res as unknown as httpResponse;
    return new Promise<httpResponse>((resolve) => {
      resolve(data);
    });
  } catch (error) {
    return Promise.reject( error as unknown as httpResponse);
  }
}

// 上传文件
async function uploadFile(
  path: string,
  files: File[],
  body = {} as { [key: string]: string }
) {
  try {
    const formData = new FormData();
    console.log(files);
    // files.forEach((file) => {
    //   formData.append('files', file);
    // });
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      formData.append('files', file);
    }
    Object.keys(body).forEach((key) => {
      formData.append(key, body[key]);
    });
    const res = await axios.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const data = res as unknown as httpResponse;
    return new Promise<httpResponse>((resolve) => {
      resolve(data);
    });
  } catch (error) {
    return Promise.reject( error as unknown as httpResponse);
  }
}

// 使用  get 获取文件流 转换成 blob 对象，并返回 url
async function getFileToBlobURL(url: string) {
  return axios.get(url, { responseType: 'blob' })
    .then(response => {
      const url = URL.createObjectURL(new Blob([response.data]));
      return url;
    })
    .catch(err => {
      console.error(err);
    })
}

function downloadFileStream(url: string, filename: string) {
  return axios.get(url, { responseType: 'blob' })
    .then(response => {
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      link.click();
      URL.revokeObjectURL(url);
    })
    .catch(err => {
      console.error(err);
    })
}
function GetByIDToUrlParams(gbi: GetByID) :string {
  return `id=${gbi.id}&with_deleted=${gbi.with_deleted}`;
}

function pageToUrlParams(pq: PageQuery) :string {
  let params = new URLSearchParams();
  params.append('page', pq.page as unknown as string);
  params.append('page_size', pq.page_size as unknown as string);
  if (pq.with_deleted) {
    params.append('with_deleted', pq.with_deleted as boolean ? 'true' : 'false');
  }
  if (pq.deleted) {
    params.append('deleted', pq.deleted as boolean ? 'true' : 'false');
  }
  if (pq.search) {
    params.append('search', pq.search);
  }
  if (pq.search_fields) {
    // console.log(pq.search_fields);
    for (let i = 0; i < pq.search_fields.length; i++) {
      // console.log(pq.search_fields[i]);
      params.append(`search_fields`, JSON.stringify(pq.search_fields[i]));
    }
  }
  if (pq.tabs) {
    pq.tabs.forEach((item) => {
      params.append(`tabs`, JSON.stringify(item));
    });
  }
  if (pq.start_at) {
    params.append('start_at', pq.start_at as string);
  }
  if (pq.end_at) {
    params.append('end_at', pq.end_at as string);
  }
  if (pq.sort_by) {
    pq.sort_by.forEach((item) => {
      params.append(`sort_by`, JSON.stringify(item));
    });
  }
  return params.toString();
}

export default {
  GetByIDToUrlParams,
  pageToUrlParams,
  post,
  get,
  put,
  del,
  uploadFile,
  getFileToBlobURL,
  downloadFileStream,
};
